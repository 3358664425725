import React, { useState, useEffect } from "react";
import {
  universitiesDetails,
  universitiesShortListedWithPagination,
} from "../../Api/Api";
import "../CSS file/univerleft.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPopularUniversity } from "../../StateManagement/slicers/universitySlicer";
import { MdBlock, MdCheckCircle } from "react-icons/md";
import { addOrRemoveUserPreferenceOnUniversity } from "../../StateManagement/slicers/userSlicer";
import PaginationTab from "../../Components/Pagination tab/PaginationTab";
import UniverSkeletonLoader from "../../Components/skeleton loader/UniverSkeletonLoader";
import CustLoader from "../../Components/Loader/CustLoader";
import { selectLoader } from "../../StateManagement/slicers/commonSlicer";
const no_shortlisted_univ = require("../../assets/shortlisted_university_img.png");

function ContentUnivercity(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.common);


  useEffect(() => {
    dispatch(getPopularUniversity());
  }, []);

  const university = useSelector((state) => state.university.university);
  const searchUniversity = useSelector((state) => state.user.searchUniversity);
  const isSearch = useSelector((state) => state.university.isSearch);

  const featuredUniversities = useSelector(
    (state) => state.university.featuredUniversities
  );
  const popularUniversities = useSelector(
    (state) => state.university.popularUniversities
  );

  const recommendedUniversities = useSelector(
    (state) => state.university.recommendedUniversities
  );

  const universityTypeToRender = useSelector(
    (state) => state.university.universityTypeToRender
  );

  const shortlistedUniversities = useSelector(
    (state) => state.university.shortlistedUniversities
  );
  const [univercityBox, setUnivercityBox] = useState([]);
  const [totalPage, setTotalPage] = useState(0);



  function shortlistUniversity(university) {
    if(universityTypeToRender === "Shortlisted Univercities") {
    dispatch(
      addOrRemoveUserPreferenceOnUniversity({
        if_shortlisted: false,
        university_id: university.perUnivercity.id,
      })
    );
    } else {

    dispatch(
      addOrRemoveUserPreferenceOnUniversity({
        if_shortlisted: !university.perUnivercity.if_shortlisted,
        university_id: university.perUnivercity.id,
      })
    );
  }

  }
  console.log(shortlistedUniversities,"line 75")

  useEffect(() => {
    if (!isSearch) {
      if (university) {
        if (university?.universities) {
          if (universityTypeToRender === "Universities") {
            let uni = university.universities;
            let count = university.count / 10;
            let value = Math.ceil(count);
            setTotalPage(value);
            setUnivercityBox(uni);
          } else if (universityTypeToRender === "Popular Univercities") {

            setUnivercityBox(popularUniversities.universities);
          } else if (universityTypeToRender === "Recommended Univercities") {
            if (
              recommendedUniversities.messages ===
              "No Recommended Universities Found"
            ) {
              setUnivercityBox([]);
            } else {
              if (recommendedUniversities.data.total_universities > 0) {
                let count = recommendedUniversities.data.total_universities / 10;
                let value = Math.ceil(count);
                setTotalPage(value);
                setUnivercityBox(recommendedUniversities.data.universities);
              }
            }
          } else if (universityTypeToRender === "Featured Univercities") {
            setUnivercityBox(featuredUniversities.universities);
          } else if (universityTypeToRender === "Shortlisted Univercities") {
            if (
              shortlistedUniversities.messages ===
              "No Shortlisted Universities Found"
            ) {
              setUnivercityBox([]);
            } else {
              if (shortlistedUniversities?.data?.universities) {
                let count1 = shortlistedUniversities?.data?.count / 10;
                let value = Math.ceil(count1);
                setTotalPage(value);
                setUnivercityBox(
                  shortlistedUniversities?.data?.universities
                );
              }
            }
          } 
        }
      }
    } else {
      if (searchUniversity.search_results) {
        setUnivercityBox(searchUniversity.search_results);

        let value = Math.ceil(searchUniversity.total_count / 10);
        setTotalPage(value);
      }
    }
  }, [
    university,
    popularUniversities,
    recommendedUniversities,
    featuredUniversities,
    universityTypeToRender,
    shortlistedUniversities,
    searchUniversity,
    isSearch
  ]);




  function handleViewDetails(e) {
    const univercityId = e?.id;
    const univercityName = e?.u_name;
    const path = `/UnivercityMain/ViewDetailUnivercity/${univercityId}/${univercityName}`;
    navigate(path);
  }

  async function paginate(number) {
    if (universityTypeToRender === "Shortlisted Univercities") {
      let data = (number - 1) * 10 + 1
      let res = await universitiesShortListedWithPagination({
        entries_from: data,
      });
      setUnivercityBox(res.data.filtered_universities);
    }
    if (universityTypeToRender === "Universities") {
    dispatch(selectLoader(true))
      let data = (number - 1) * 10 + 1
      let res = await universitiesDetails({
        entries_from: data,
      });
      setUnivercityBox(res.data.universities);
      dispatch(selectLoader(false))
    }
  }

  const renderUniversity = univercityBox.map((perUnivercity, index) => {
    if (univercityBox) {
      const univercity_img = require("../../assets/image 1.png");
      return (
        <div className="univercity_box_contianer" key={Math.random()}>
          <div>
            <div className="univer_contianer">
              <img
                loading="lazy"
                className="univercity_img"
                src={
                  perUnivercity?.university_cover_img
                    ? perUnivercity?.university_cover_img
                    : univercity_img
                }
                alt="univercity_img"
              />
            </div>

            <h6 className="title_of_unicercity" title={perUnivercity?.u_name}>
              {perUnivercity?.u_name
                ? perUnivercity?.u_name.substring(0, 30) + " ..."
                : "------"}
            </h6>
            <p className="sub_title_of_univervity">
              {perUnivercity?.university_locations[0]?.university_in_city[0]
                .city_name.length > 0
                ? perUnivercity?.university_locations[0]?.university_in_city[0]
                  .city_name
                : "-----"}{" "}
              ,{" "}
              {perUnivercity?.university_locations[0]?.university_in_country[0]
                ?.country_name
                ? perUnivercity?.university_locations[0]
                  ?.university_in_country[0]?.country_name
                : "-----"}{" "}
            </p>
          </div>

          <div className="view_details_contianer">
            <button
              type="button"
              className="like_icon_container"
              onClick={() => {
                shortlistUniversity({
                  perUnivercity: perUnivercity,
                  index: index,
                });
              }}
            >
              {

                perUnivercity?.if_shortlisted
                
                ? (

                  <div className="icon_block_text_container">
                    <MdCheckCircle className="blue_color" /> Shortlisted
                  </div>
                ) : (
                  <div className="icon_block_text_container">
                    <MdBlock className="red_color" /> Shortlist
                  </div>
                )}
            </button>
            <button
              className="view_detail_btn_univercity"
              onClick={() => handleViewDetails(perUnivercity)}
            >
              details
            </button>
          </div>
        </div>
      );
    }
  });
  

  return (
    <>
      {isLoading ? <CustLoader />
        : null}
      {univercityBox.length > 0 ? (
        renderUniversity
      // ) : message === "No Universities Found" ? (
      //   <div className="no_likes_container center_no_likes_container">
      //     <img src={no_shortlisted_univ} alt="dwec_like" loading="lazy" />
      //   </div>
      ) : (
        // <UniverSkeletonLoader />
        <img src={no_shortlisted_univ} alt="dwec_like" loading="lazy" />
      )}
      {univercityBox.length > 0 ? (
        <PaginationTab
          postsPerPage={totalPage}
          totalPosts={univercityBox.length}
          paginate={paginate}
        />
      ) : null}


    </>
  );
}

export default ContentUnivercity;
