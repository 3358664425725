import { createSlice } from "@reduxjs/toolkit";
import {
  userLoginInfo,
  profileUpdate,
  updateUserInterest,
  addWorkExperience,
  addEducationalBackground,
  updateStoreEmergencyContactDetails,
  updateStoreSocialBackgroundsDetails,
  uploadDocuments,
  deleteUploadDocument,
  deleteTestInfo,
  addRemoveUserPreferenceOnUniversities,
  addRemoveUserPreferenceOnCourses,
  uploadedDocuments,
  documentsToBeUploaded,
  fetchEducationInfoById,
  updateWorkExperience,
  deleteWorkExperience,
  fetchEducationDetail,
  deleteUserEducationDetail,
  updateUserEducationDetail,
  uploadUserPost,
  fetchUserPost,
  fetchUserGallery,
  fetchUserFeedsWall,
  deleteUserPost,
  sendInteractionOnPost,
  searchUniversalData,
  viewUserProfile,
  userFriendGallery,
  userFriendFeedPage,
  searchIntoCategoriesData,
  userSendInteractionWithOthers,
  userTestInfo,
  userAddCommentOnFeed,
  fetchUserMyConnection
} from "../../Api/Api";

import { fetchUniversity, fetchUniversityCourses, getShortlistedUniversity } from "./universitySlicer";
import { getShortlistedCourse } from "./courseSlicer";
import { selectLoader } from "./commonSlicer";

export const userSlicer = createSlice({
  name: "user",
  initialState: {
    user: {},
    updateUserDetails: {},
    ifUpdated: null,
    isLoading: false,
    errorMessage: null,
    successMessage: null,
    documentsToBeUploadedByUser: [],
    uploadedDocumentsByUser: [],
    getWorkExperienceById: {},
    updateWorkExperienceDetails: {},
    getEducationById: {},
    updateEducationDetail: {},
    userPost: [],
    userGallery: [],
    search: [],
    storeUserBySearch: {},
    storeUserFriendGallery: [],
    storeuserFriendFeedPage: [],
    searchUniversity: [],
    searchCourses: [],
    userWall: [],
    connectionTypeToRender: "Followers",
    userConnectionList: [],
    tabToRender: 1,
  },
  reducers: {
    toggleLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    fetchUser: (state, action) => {
      state.user = action.payload.data;
      state.isLoading = false;
    },
    storeUser: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    emptyErrorMessage: (state) => {
      state.errorMessage = null;
      state.isLoading = false;
    },
    emptySuccessMessage: (state) => {
      state.successMessage = null;
      state.isLoading = false;
    },
    storeSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
      state.isLoading = false;
    },
    storeErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    storeDocumentsToBeUploaded: (state, action) => {
      state.documentsToBeUploadedByUser = action.payload;
      state.isLoading = false;
    },
    storeUploadedDocuments: (state, action) => {
      state.uploadedDocumentsByUser = action.payload;
      state.isLoading = false;
    },
    storeWorkExperienceValueById: (state, action) => {
      state.getWorkExperienceById = action.payload;
      state.isLoading = false;
    },
    storeEducationValueById: (state, action) => {
      state.getEducationById = action.payload;
      state.isLoading = false;
    },
    updateWorkExperienceData: (state, action) => {
      state.updateWorkExperienceDetails = action.payload;
      state.isLoading = false;
    },
    updateEducationData: (state, action) => {
      state.updateEducationDetail = action.payload;
      state.isLoading = false;
    },
    fetchUserUpload: (state, action) => {
      state.userPost = action.payload;
    },
    storeUserFeedWall: (state, action) => {
      state.userWall = action.payload;
    },
    fetchUserGalleryData: (state, action) => {
      state.userGallery = action.payload;
    },
    fetchSearch: (state, action) => {
      state.search = action.payload;
    },
    fetchSearchCategory: (state, action) => {
      state.searchCategory = action.payload;
    },

    storeUserBySearchValue: (state, action) => {

      state.storeUserBySearch = action.payload;
    },
    storeUserFriendPageById: (state, action) => {

      state.storeuserFriendFeedPage = action.payload;
    },
    storeUserFriendGalleryById: (state, action) => {

      state.storeUserFriendGallery = action.payload;
    },
    storeSearchUniversityCategory: (state, action) => {
      
      state.searchUniversity = action.payload;
    },
    storeSearchCoursesCategory: (state, action) => {
      
      state.searchCourses = action.payload;
    },
    storeConnectionTypeToRender: (state, action) => {
      state.connectionTypeToRender = action.payload.type;
      state.tabToRender = action.payload.tab;
    },
    storeUserMyConnectionDetails: (state, action) => {
      state.userConnectionList = action.payload;
    },

  },
});

// Action creators are generated for each case reducer function
export const {
  fetchUser,
  storeUser,
  updateUser,
  toggleLoader,
  emptyErrorMessage,
  emptySuccessMessage,
  storeErrorMessage,
  storeSuccessMessage,
  storeDocumentsToBeUploaded,
  storeUploadedDocuments,
  storeWorkExperienceValueById,
  updateWorkExperienceData,
  storeEducationValueById,
  updateEducationData,
  fetchUserUpload,
  fetchUserGalleryData,
  fetchSearch,
  fetchSearchCategory,
  storeUserBySearchValue,
  storeUserFriendGalleryById,
  storeuserFriendFeedPage,
  storeSearchUniversityCategory,
  storeSearchCoursesCategory,
  storeUserFeedWall,
  storeConnectionTypeToRender,
  storeUserMyConnectionDetails
} = userSlicer.actions;

export const fetchLoggedInUser = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await userLoginInfo();
  dispatch(fetchUser(data));
};

export const updateLoggedInUser = (data) => async (dispatch) => {
  
  dispatch(toggleLoader(true));
  const response = await profileUpdate(data);
  dispatch(fetchLoggedInUser());
};

export const userInterestUpdateValue = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));

  const response = await updateUserInterest(data);
  dispatch(fetchLoggedInUser());
};

export const addUserWorkExperience = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await addWorkExperience(data);
  dispatch(fetchLoggedInUser());
};

export const addUserEducationalBackground = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await addEducationalBackground(data);
  dispatch(fetchLoggedInUser());
};
export const deleteUserEducationalBackground = (id) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await deleteUserEducationDetail(id);
  dispatch(fetchLoggedInUser());
};


export const addOrUpdateUserEmergencyContacts = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await updateStoreEmergencyContactDetails(data);
  dispatch(fetchLoggedInUser());
};

export const addOrUpdateUserSocialBackground = (data) => async (dispatch) => {
  dispatch(`toggleLoader`(true));
  const response = await updateStoreSocialBackgroundsDetails(data);
  dispatch(fetchLoggedInUser());
};

export const uploadUserDocuments = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await uploadDocuments(data);

  dispatch(fetchDocumentsUploaded());
};

export const addUserTestInfo = (data) => async (dispatch) => {

  dispatch(toggleLoader(true));
  const response = await userTestInfo(data);

  dispatch(fetchLoggedInUser());
};


export const addOrRemoveUserPreferenceOnUniversity = (data) => async (
  dispatch
) => {
  dispatch(selectLoader(true));
  await addRemoveUserPreferenceOnUniversities(data);
  dispatch(getShortlistedUniversity());
  dispatch(fetchUniversity())
};

export const addOrRemoveUserPreferenceOnCourses = (data) => async (
  dispatch
) => {
  dispatch(selectLoader(true));
  await addRemoveUserPreferenceOnCourses(data);
  
  dispatch(getShortlistedCourse());
  dispatch(fetchUniversityCourses())
};

export const fetchDocumentsToBeUploadedByUser = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await documentsToBeUploaded();
  
  dispatch(storeDocumentsToBeUploaded(response.data));
  
};

export const fetchDocumentsUploaded = () => async (dispatch) => {
 
  dispatch(toggleLoader(true));
  const response = await uploadedDocuments();
  dispatch(storeUploadedDocuments(response.data));
  
};

export const fetchUserWorkExperienceById = (data) => async (dispatch) => {
  
  dispatch(toggleLoader(true));
  const response = await fetchEducationInfoById(data);

  dispatch(storeWorkExperienceValueById(response.data));
};
export const updateUserWorkExperience = (data, id) => async (dispatch) => {
 
  dispatch(toggleLoader(true));
  const response = await updateWorkExperience(data, id);

  dispatch(fetchLoggedInUser());
};

export const fetchUserEducation = (data) => async (dispatch) => {
  const response = await fetchEducationDetail(data);

  dispatch(storeEducationValueById(response.data));
};

export const deleteWorkExperienceData = (id) => async (dispatch) => {

  dispatch(toggleLoader(true));
  const response = await deleteWorkExperience(id);
  dispatch(fetchLoggedInUser());
};
export const deleteUserTestInfo = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await deleteTestInfo(data);
  
  dispatch(fetchLoggedInUser());
};
export const deleteUploadDocumentById = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await deleteUploadDocument(data);
  
  dispatch(uploadUserDocuments());
};

export const updateUserEducation = (data, id) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await updateUserEducationDetail(data, id);
  dispatch(fetchLoggedInUser());
};

export const userUploadPost = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  try {
    const response = await uploadUserPost(data);
    dispatch(fetchUserFeedWall());
    return response; // Return the response to the calling function
  } catch (error) {
    return { flag: false, messages: error.message }; // Return an error response
  } finally {
    dispatch(toggleLoader(false));
  }
};

export const fetchUserUploadPost = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await fetchUserPost(data);
  dispatch(fetchUserUpload(response.data));
};

export const fetchUserFeedWall = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await fetchUserFeedsWall(data);
  dispatch(storeUserFeedWall(response.data));
};

export const fetchUserGalleryPhotos = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await fetchUserGallery(data);
  dispatch(fetchUserGalleryData(response.data));
};

export const deleterUserPostById = (id) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await deleteUserPost(id);
  dispatch(fetchUserFeedWall());
};

export const userSendInteractionOnPost = (data, id) => async (dispatch) => {
 
  dispatch(toggleLoader(true));
  const response = await sendInteractionOnPost(data, id);
  dispatch(fetchUserFeedWall());
};

export const fetchSearchUniversal = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await searchUniversalData(data);
  dispatch(fetchSearch(response.data));
};

export const fetchsearchIntoCategoriesData = (data,current) => async (dispatch) => {

  dispatch(selectLoader(true))
  const response = await searchIntoCategoriesData(data);
  if(current === "Universities"){
    dispatch(storeSearchUniversityCategory(response.data));
    dispatch(selectLoader(false))
  }else if(current === "Courses"){
  dispatch(storeSearchCoursesCategory(response.data));
  dispatch(selectLoader(false))
  }else if(current === "Shortlisted Univercities"){
  dispatch(storeSearchUniversityCategory(response.data));
  dispatch(selectLoader(false))
  }
};

export const fetchUserProfileBySearch = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await viewUserProfile(data);
  dispatch(storeUserBySearchValue(response.data));
};

export const fetchUserFriendFeedPage = (id) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await userFriendFeedPage(id);
  dispatch(storeDocumentsToBeUploaded(response.data));
  
};

export const fetchUserFriendGallery = (id) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await userFriendGallery(id);
  dispatch(storeUserFriendGalleryById(response.data));
  
};
export const storeUserSendInteractionWithOthers = (data) => async (
  dispatch
) => {
  dispatch(toggleLoader(true));
  const response = await userSendInteractionWithOthers(data);
  dispatch(userConnectionWithOthers());
  
};


export const userAddCommentOnFeeds = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await userAddCommentOnFeed(data);
  dispatch(fetchUserFeedWall());
};

export const userConnectionWithOthers = () => async (dispatch) => {
  const response = await fetchUserMyConnection();
  dispatch(storeUserMyConnectionDetails(response));
};


export const selectUserFullNameParams = (state) => {
  const user = state.user.user;

  if (!user || !user.user_personal_profile) {
    return '';
  }

  const { first_name, mid_name, last_name, id } = user.user_personal_profile;
  return `${first_name ?? ""} ${mid_name ? mid_name + "-" : ""}${last_name ??
    ""}-${id}`
    .trim()
    .toLowerCase()
    .replace(/\s/g, "-");
};

export default userSlicer.reducer;