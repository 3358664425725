import React, { useState } from "react";
import "../CSS file/connectionmain.css";
import CommonHeader from "../../Components/Common header/CommonHeader";
import ConnectionLeftSide from "./ConnectionLeftSide";
import ConnectionRightSide from "./ConnectionRightSide";
import { MdOutlineSearch } from "react-icons/md";
import CategorySearch from "../../Components/CategorySearch";
import { useSelector } from "react-redux";

export default function ConnectionMain() {
  function handleConnSearch() {}
  const university = useSelector((state) => state.university.university);
 
  const shortlistedUniversities = useSelector(
    (state) => state.university.shortlistedUniversities
  );


  return (
    <div className="main_connection_contianer">
      <CommonHeader />
      <div className="conn_title_search_bar_contianer">
        <h4 className="conn_title">Connection</h4>

        <CategorySearch />
      </div>

      <hr className="conn_line" />

      <div className="connection_under_main_container">
        <div className="left_side_connection_contianer">
          <ConnectionLeftSide />
        </div>
        <div className="right_side_connection_contianer">
          <ConnectionRightSide />
        </div>
      </div>
    </div>
  );
}
