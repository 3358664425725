import React from "react";

function UniverSkeletonLoader(props) {
  return (
    <>
    <div className="univercity_box_contianer " key={Math.random()}>
      <div>
        <div className="univer_contianer skeleton skeleton_remove_border">
          <div className="univercity_img skeleton_remove_border" />
        </div>

        <div className="skeleton skeleton_margin skeleton_text skeleton_width" />

        <div
          style={{ marginLeft: 5 }}
          className="skeleton  skeleton_text skeleton_width_short"
        />
      </div>

      <div className="view_details_contianer skeleton_padding_content_height_univercity">
        <div className=" skeleton_width_short  skeleton skeleton_text" />

        <button className="view_detail_btn_univercity skeleton skeleton_button skeleton_text skeleton_remove_border" />
      </div>
    </div>


    <div className="univercity_box_contianer " key={Math.random()}>
      <div>
        <div className="univer_contianer skeleton skeleton_remove_border">
          <div className="univercity_img skeleton_remove_border" />
        </div>

        <div className="skeleton skeleton_margin skeleton_text skeleton_width" />

        <div
          style={{ marginLeft: 5 }}
          className="skeleton  skeleton_text skeleton_width_short"
        />
      </div>

      <div className="view_details_contianer skeleton_padding_content_height_univercity">
        <div className=" skeleton_width_short  skeleton skeleton_text" />

        <button className="view_detail_btn_univercity skeleton skeleton_button skeleton_text skeleton_remove_border" />
      </div>
    </div>



    <div className="univercity_box_contianer " key={Math.random()}>
      <div>
        <div className="univer_contianer skeleton skeleton_remove_border">
          <div className="univercity_img skeleton_remove_border" />
        </div>

        <div className="skeleton skeleton_margin skeleton_text skeleton_width" />

        <div
          style={{ marginLeft: 5 }}
          className="skeleton  skeleton_text skeleton_width_short"
        />
      </div>

      <div className="view_details_contianer skeleton_padding_content_height_univercity">
        <div className=" skeleton_width_short  skeleton skeleton_text" />

        <button className="view_detail_btn_univercity skeleton skeleton_button skeleton_text skeleton_remove_border" />
      </div>
    </div>
    <div className="univercity_box_contianer " key={Math.random()}>
      <div>
        <div className="univer_contianer skeleton skeleton_remove_border">
          <div className="univercity_img skeleton_remove_border" />
        </div>

        <div className="skeleton skeleton_margin skeleton_text skeleton_width" />

        <div
          style={{ marginLeft: 5 }}
          className="skeleton  skeleton_text skeleton_width_short"
        />
      </div>

      <div className="view_details_contianer skeleton_padding_content_height_univercity">
        <div className=" skeleton_width_short  skeleton skeleton_text" />

        <button className="view_detail_btn_univercity skeleton skeleton_button skeleton_text skeleton_remove_border" />
      </div>
    </div>

    <div className="univercity_box_contianer " key={Math.random()}>
      <div>
        <div className="univer_contianer skeleton skeleton_remove_border">
          <div className="univercity_img skeleton_remove_border" />
        </div>

        <div className="skeleton skeleton_margin skeleton_text skeleton_width" />

        <div
          style={{ marginLeft: 5 }}
          className="skeleton  skeleton_text skeleton_width_short"
        />
      </div>

      <div className="view_details_contianer skeleton_padding_content_height_univercity">
        <div className=" skeleton_width_short  skeleton skeleton_text" />

        <button className="view_detail_btn_univercity skeleton skeleton_button skeleton_text skeleton_remove_border" />
      </div>
    </div>


    <div className="univercity_box_contianer " key={Math.random()}>
      <div>
        <div className="univer_contianer skeleton skeleton_remove_border">
          <div className="univercity_img skeleton_remove_border" />
        </div>

        <div className="skeleton skeleton_margin skeleton_text skeleton_width" />

        <div
          style={{ marginLeft: 5 }}
          className="skeleton  skeleton_text skeleton_width_short"
        />
      </div>

      <div className="view_details_contianer skeleton_padding_content_height_univercity">
        <div className=" skeleton_width_short  skeleton skeleton_text" />

        <button className="view_detail_btn_univercity skeleton skeleton_button skeleton_text skeleton_remove_border" />
      </div>
    </div>



    <div className="univercity_box_contianer " key={Math.random()}>
      <div>
        <div className="univer_contianer skeleton skeleton_remove_border">
          <div className="univercity_img skeleton_remove_border" />
        </div>

        <div className="skeleton skeleton_margin skeleton_text skeleton_width" />

        <div
          style={{ marginLeft: 5 }}
          className="skeleton  skeleton_text skeleton_width_short"
        />
      </div>

      <div className="view_details_contianer skeleton_padding_content_height_univercity">
        <div className=" skeleton_width_short  skeleton skeleton_text" />

        <button className="view_detail_btn_univercity skeleton skeleton_button skeleton_text skeleton_remove_border" />
      </div>
    </div>
    <div className="univercity_box_contianer " key={Math.random()}>
      <div>
        <div className="univer_contianer skeleton skeleton_remove_border">
          <div className="univercity_img skeleton_remove_border" />
        </div>

        <div className="skeleton skeleton_margin skeleton_text skeleton_width" />

        <div
          style={{ marginLeft: 5 }}
          className="skeleton  skeleton_text skeleton_width_short"
        />
      </div>

      <div className="view_details_contianer skeleton_padding_content_height_univercity">
        <div className=" skeleton_width_short  skeleton skeleton_text" />

        <button className="view_detail_btn_univercity skeleton skeleton_button skeleton_text skeleton_remove_border" />
      </div>
    </div>


    </>
  );
}

export default UniverSkeletonLoader;
