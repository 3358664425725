import React, { useEffect, useState } from "react";
import { universitiesCoursesDetailsById } from "../../Api/Api";
import "../CSS file/viewdetailsunivercity.css";
import { MdBlock, MdCheckCircle } from "react-icons/md";
import CommonHeader from "../../Components/Common header/CommonHeader";
import DetailsOfUnivercityLeftRight from "./DetailsOfUnivercityLeftRight";
import { useNavigate, useParams } from "react-router-dom";
import { fetchUniversityById } from "../../StateManagement/slicers/universitySlicer";
import { useSelector, useDispatch } from "react-redux";
import PaginationTab from "../../Components/Pagination tab/PaginationTab";
import { fetchUniversityCoursesById } from "../../StateManagement/slicers/courseSlicer";
import { storeRenderingUniversityCourses } from "../../StateManagement/slicers/universitySlicer";
import { userSendInteractionOnPost } from "../../StateManagement/slicers/userSlicer";
import { addOrRemoveUserPreferenceOnCourses } from "../../StateManagement/slicers/userSlicer";
import InsideCoursePartSkeleton from "../../Components/skeleton loader/view details university/InsideCoursePartSkeleton";
import FloatingShortlistedBtn from "../../Components/FloatingShortlistedBtn";
import { formatURLName } from "../../utils/helpers";
import MapComponent from "./Map";

const univercity_img = require("../../assets/image 3.png");

function ViewDetailUnivercity() {
  const { univercityId, univercityName } = useParams();
  const navigate = useNavigate();
  let dispatch = useDispatch();



  const [clickCourseData, setClickCourseData] = useState();

  const [selectedValue, setSelectedValue] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(10);
  const universityToRender = useSelector(
    (state) => state.university.universityToRender
  );


 
  const {
    shortlistedCourses,
    courseTypeToRender,
    popularCourses,
    recommendedCourses,
    featuredCourses,
  } = useSelector((state) => state.course);

  const [courseLists, setCourseLists] = useState([]);

  // useEffect(() => {
  //   if (localStorage.getItem("university_id")) {
  //     let id = localStorage.getItem("university_id");

  //     dispatch(fetchUniversityById(id));
  //   }
  // }, []);

  useEffect(() => {
    if (univercityId && univercityName) {
      dispatch(fetchUniversityById(univercityId, univercityName));
    }
  }, [univercityId, univercityName, dispatch]);
  

  useEffect(() => {
    if (universityToRender) {
      console.log('universityToRender', universityToRender)
      
      if (universityToRender?.data?.university_courses) {
        if (universityToRender.data.university_courses.length > 0) {
          setCourseLists(universityToRender?.data?.university_courses);
        }
      }
    }
  }, [universityToRender]);



  function handleViewCourse() {
    setClickCourseData(!clickCourseData);
  }


  
  function shortlistCourse(course) {

    const index = selectedValue.indexOf(course.id);
    if (index > -1) {
      setSelectedValue(selectedValue.filter((i) => i !== course.id));
      dispatch(
        addOrRemoveUserPreferenceOnCourses({
          if_shortlisted: false,
          course_id: course.course_id,
          university_id: course.university_id,
        })
      );
    } else {
      setSelectedValue([...selectedValue, course.id]);
      dispatch(
        addOrRemoveUserPreferenceOnCourses({
          if_shortlisted: true,
          course_id: course.course_id,
          university_id: course.university_id,
        })
      );
    }
  }

  async function handleCourseSelect(e) {
    let res = await universitiesCoursesDetailsById(e.course_id);
    dispatch(storeRenderingUniversityCourses(res.data));
   
    const formattedName = formatURLName(e.course_has_entries_in_course.name);
    navigate(`/UnivercityMain/ViewDetailCourses/${formattedName}`);
  }

  let indexOfLastPost = currentPage * postPerPage;
  let indexOfFirstPost = indexOfLastPost - postPerPage;
  let currentPosts = courseLists.slice(indexOfFirstPost, indexOfLastPost);

  function paginate(number) {
    setCurrentPage(number);
  }

  const renderCourseContent = currentPosts.map((perClgContent, index) => {
    return (
      <button className="view_course_univercity_box" key={Math.random()}>
        <div className="view_course_univer_button_container">
          <h5
            className="course_text_title"
            title={perClgContent?.course_has_entries_in_course?.name}
          >
            {perClgContent?.course_has_entries_in_course?.name
              ? perClgContent?.course_has_entries_in_course?.name.substring(
                0,
                42
              ) + "..."
              : null}
          </h5>
          <h6 className="start_date_text">Duration</h6>
          <h6 className="sub_start_text">
            {perClgContent?.course_has_entries_in_course?.course_of_duration[0]
              ?.duration_range
              ? perClgContent?.course_has_entries_in_course
                ?.course_of_duration[0]?.duration_range
              : "--/--/--"}
          </h6>
        </div>
        <div className="view_details_contianer_course">
          <button
            type="button"
            className={
              perClgContent?.if_shortlisted === true ||
                shortlistedCourses?.if_shortlisted === 1 ||
                selectedValue.includes(perClgContent.id)
                ? "like_icon_container"
                : "like_icon_container red_color"
            }
            onClick={() => shortlistCourse(perClgContent)}
          >
            {perClgContent?.if_shortlisted === true ||
              selectedValue.includes(perClgContent.id) ? (
              <div className="icon_block_text_container">
                <MdCheckCircle className="blue_color" /> Shortlisted
              </div>
            ) : (
              <div className="icon_block_text_container">
                <MdBlock className="red_color" /> Shortlist
              </div>
            )}
          </button>
          <button
            className="view_detail_btn_course"
            onClick={() => handleCourseSelect(perClgContent)}
          >
            View
          </button>
        </div>
      </button>
    );
  });


  return (
    <div className="main_connection_contianer">
      <CommonHeader />
      <div className="view_details_main_container">
        <div className="view_details_course_btn_img_container">
          {universityToRender?.data?.university_cover_img ? (
            <div className="view_details_img_container11">
              <img
                loading="lazy"
                src={universityToRender?.data?.university_cover_img}
                alt={univercity_img}
                className="view_details_univercity_img"
              />
            </div>
          ) : (
            <>
              {/* skeleton loader start */}
              <div className="view_details_img_container11 skeleton_remove_border">
                <div className="view_details_univercity_img skeleton skeleton_remove_border" />
              </div>
            </>
          )}

          <button
            className="view_course_details_btn"
            onClick={handleViewCourse}
          >
            View Courses
          </button>
        </div>
        {universityToRender?.data?.u_name ? (
          <h1 className="clg_name_view_details_text">
            <span style={{ fontSize: 25, marginRight: "1%" }}>&#9679;</span>
            {universityToRender?.data?.u_name
              ? universityToRender?.data?.u_name
              : "----"}
          </h1>
        ) : (
          <div style={{ margin: 10 }}>
            {/* skeleton start */}
            <div className="clg_name_view_details_text skeleton skeleton_text skeleton_margin skeleton_width" />
            <div className="clg_name_view_details_text skeleton skeleton_text skeleton_margin skeleton_width" />
            <div className="clg_name_view_details_text skeleton skeleton_text skeleton_margin skeleton_width_short" />
          </div>
        )}

        {clickCourseData ? (
          <div key={Math.random()}>
            <h5 className="view_details_heading_text1">
              ↪ Inside of Universities courses
            </h5>
            <div className="wrap_contianer">
              {courseLists.length > 0 ? (
                <>
                  {renderCourseContent}
                  <div className="pagination_event_tab_container">
                    <PaginationTab
                      postsPerPage={Math.ceil(courseLists.length / 10)}
                      totalPosts={courseLists.length / 10}
                      paginate={paginate}
                    />
                  </div>
                </>
              ) : (
                <InsideCoursePartSkeleton />
              )}
            </div>
          </div>
        ) : (
          <DetailsOfUnivercityLeftRight />
        )}
      </div>

      <FloatingShortlistedBtn />
    </div>
  );
}

export default ViewDetailUnivercity;
