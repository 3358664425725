import React, { useState, useEffect } from "react";
import "./paginaitontab.css";

function PaginationTab(props) {
  const [activeColor, setActiveColor] = useState(1);
  const [currentPage, setCurrentPage] = useState(2);



  const pageNumbers = [];
  for (let i = 1; i <= props.postsPerPage; i++) {
    pageNumbers.push(i);


  }

  const handleSelectPagination = (e) => {
    setActiveColor(e);
  };

  const renderPagination = pageNumbers.map((perPagiantion) => {
    return (
      <a
        href="#pagination"
        key={Math.random()}
        className={
          activeColor === perPagiantion
            ? "pagination_tab_btn active"
            : "pagination_tab_btn"
        }
        onClick={() => {
          handleSelectPagination(perPagiantion);
          props.paginate(perPagiantion);
        }}
      >
        {perPagiantion}
      </a>
    );
  });

  return (
    <div className="main_pagination_tab_container">
      <div className="pagination_box_container">
        {pageNumbers.includes(currentPage + 1) && (
          <a
            href="#prevPage"
            className="pagination_tab_btn"
            onClick={() => {
              setCurrentPage(currentPage + 1);
              props.paginate(currentPage + 1);
            }}
          >
            <strong>&#8592;</strong>
          </a>
        )}
        <div className="center_pagination">{renderPagination}</div>

        {pageNumbers.includes(currentPage + 1) && (
          <a
            href="#nextPage"
            className="pagination_tab_btn"
            onClick={() => {
              setCurrentPage(currentPage + 1);
              props.paginate(currentPage + 1);
            }}
          >
            &#10140;
          </a>
        )}

      </div>
    </div>
  );
}

export default PaginationTab;
