import React, { useState } from "react";
import "../CSS file/connright.css";
import PeopleImgTitle from "../../Components/people you may know/PeopleImgTitle";
import TitleNumberFile from "../../Components/Title and number/TitleNumberFile";
const people_img = require("../../assets/profile_pic.jpg");

export default function ConnectionRightSide() {
    //  const[connections,setConnections] = useState([])

  //   useEffect(()=>{
  //     connectionDetails()
  //   },[])
 
  //   async function connectionDetails(){ 
  //     let response = await userConnectionDetails()
  //     setConnections(response)
  //   }
  return (
    <div className="conectin_under_right_side_contianer">
      <h6 className="people_main_title_text">People You May Know</h6>
      <PeopleImgTitle
        valueToRender={[
          {
            people_img: people_img,
            peopleTitle: "Allan Ray Palban",
            peopleSubTitle: "Senior Business Analyst",
          },
          {
            people_img: people_img,
            peopleTitle: "Allan Ray Palban",
            peopleSubTitle: "Senior Business Analyst",
          },
          {
            people_img: people_img,
            peopleTitle: "Allan Ray Palban",
            peopleSubTitle: "Senior Business Analyst",
          },
          {
            people_img: people_img,
            peopleTitle: "Allan Ray Palban",
            peopleSubTitle: "Senior Business Analyst",
          },
          {
            people_img: people_img,
            peopleTitle: "Allan Ray Palban",
            peopleSubTitle: "Senior Business Analyst",
          },
        ]}
      />

      <div className="dicover_by_postion_container">
        <h6 className="people_main_title_text">DISCOVER BY POSITION</h6>

        <TitleNumberFile
          valueToRender={[
            {
              titlePostion: "Software Engineer",
              numberPostion: "20",
            },
            {
              titlePostion: "Software Engineer",
              numberPostion: "20",
            },
            {
              titlePostion: "Software Engineer",
              numberPostion: "20",
            },
            {
              titlePostion: "Software Engineer",
              numberPostion: "20",
            },
            {
              titlePostion: "Software Engineer",
              numberPostion: "20",
            },
          ]}
        />
      </div>

      <div className="dicover_by_postion_container">
        <h6 className="people_main_title_text">DISCOVER BY LOCATION</h6>

        <TitleNumberFile
          valueToRender={[
            {
              titlePostion: "San Francisco, California",
              numberPostion: "20",
            },
            {
              titlePostion: "Los Angeles, California",
              numberPostion: "20",
            },
            {
              titlePostion: "Las Vegas, Nevada",
              numberPostion: "20",
            },
            {
              titlePostion: "Austin, Texas",
              numberPostion: "20",
            },
            {
              titlePostion: "Arlington, Nebraska",
              numberPostion: "20",
            },
          ]}
        />
      </div>
    </div>
  );
}
