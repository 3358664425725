import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShortlistedCourse } from "../../../StateManagement/slicers/courseSlicer";
import {
  universitiesCoursesWithPagination,
  courseShortListedWithPagination,
} from "../../../Api/Api";
import { Link, useNavigate } from "react-router-dom";
import "../../CSS file/applicationcontent.css";
import PaginationTab from "../../../Components/Pagination tab/PaginationTab";
import { formatURLName } from "../../../utils/helpers";
const clg_img = require("../../../assets/clg_profile_pic.png");
const no_data = require("../../../assets/no_data.svg");

function CourseShortlist(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shortListedLists, setShortListedLists] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const [courseContent, setCourseContent] = useState([]);

  const [message, setMessage] = useState("");

  const { shortlistedCourses } = useSelector((state) => state.course);
  useEffect(() => {
    dispatch(getShortlistedCourse());
  }, [dispatch]);

  useEffect(() => {
    if (shortlistedCourses) {
      if (shortlistedCourses.messages === "No Shortlisted Courses Found") {
        setMessage("No Courses Found");
        setShortListedLists([]);
      } else {
        if (shortlistedCourses?.data?.shortlisted_courses) {
          let count = shortlistedCourses.data.total_count / 10;
          let value = Math.ceil(count);
          setTotalPage(value);
          setShortListedLists(shortlistedCourses.data.shortlisted_courses);
        }
      }
    }
  }, [shortlistedCourses]);
  async function paginate(number) {
    let data = number * 10 + 1 - 10;
    let res = await courseShortListedWithPagination({
      entries_from: data,
    });
    setShortListedLists(res.data.courses);
  }

  function handleViewDetails(e) {
    const formattedName = formatURLName(e.course_has_entries_in_course.name);
    navigate(`/UnivercityMain/ViewDetailCourses/${formattedName}`);
  }

  const allDataOfAplication = [
    {
      courseName: `Cyber Security`,
      univercityImage: clg_img,
      univercityName: `University of alberta `,
      countryName: `United States`,
      durationCourse: `1.2 years`,
    },
    {
      courseName: `Computer Engineering`,
      univercityImage: clg_img,
      univercityName: `University of Toronto `,

      countryName: `Canada`,
      durationCourse: `3 years`,
    },
  ];

  const renderDataOfApplication = shortListedLists.map((perApkData) => {
    return (
      <tr className="data_table_content" key={Math.random()}>
        <td>
          {" "}
          <p
            className="stage_text"
            title={perApkData?.course_has_entries_in_course?.name}
          >
            {perApkData?.course_has_entries_in_course?.name
              ? perApkData?.course_has_entries_in_course?.name.substring(
                  0,
                  42
                ) + "..."
              : "-----"}
          </p>
        </td>
        <td className="data_content_container">
          <div className="clg_img_container_circle">
            <img
              src={
                perApkData?.course_given_by_university?.university_logo
                  ? perApkData?.course_given_by_university?.university_logo
                  : "----"
              }
              alt="clg_pic"
              className="clg_img_self"
              loading="lazy"
            />
          </div>
          <div className="univ_date_container">
            <p
              className="univ_title_text"
              title={perApkData?.course_given_by_university?.u_name}
            >
              {perApkData?.course_given_by_university?.u_name
                ? perApkData?.course_given_by_university?.u_name
                : "----"}
            </p>
            {/* <p>{perApkData?.course_has_entries_in_course?.course_of_duration
[0]?.duration_range}</p> */}
            {/* <p className="date_text_">Starting :{perApkData.universityName} </p> */}
          </div>
        </td>
        <td>
          <p className="stage_text">
            {" "}
            {perApkData?.universities_location[0]?.university_in_country[0]
              ?.country_name
              ? perApkData?.universities_location[0]?.university_in_country[0]
                  ?.country_name
              : "----"}
          </p>
        </td>
        <td>
          <p className="stage_text">
            {perApkData?.course_has_entries_in_course?.course_of_duration[0]
              ?.duration_range
              ? perApkData?.course_has_entries_in_course?.course_of_duration[0]
                  ?.duration_range
              : "-----"}
          </p>
        </td>

        <td>
          <button className="view_btn_container" onClick={()=>handleViewDetails(perApkData)}>View</button>
        </td>
      </tr>
    );
  });
  return (
    <div className="application_content_box_container">
      <div className="application_main_content_table">
        {shortListedLists.length > 0 ? (
          <table className="heading_container">
            <tr className="data_table_content">
              <th>Course name</th>
              <th>Univercity name</th>
              <th>Country</th>
              <th>Duration</th>
              <th>Action</th>
            </tr>
            {renderDataOfApplication}
          </table>
        ) : (
          <div className="no_likes_container center_no_likes_container">
            <img src={no_data.default} alt="no_data" loading="lazy" />
            <Link to="/UnivercityMain">
              Go to the shortlisted section if you do not have shortlisted
            </Link>
          </div>
        )}
      </div>
      <div className="pagination_tab_univercity_course_container">
        {shortListedLists.length > 0 ? (
          <PaginationTab
            postsPerPage={totalPage}
            totalPosts={shortListedLists.length}
            paginate={paginate}
          />
        ) : null}
      </div>
    </div>
  );
}

export default CourseShortlist;
