import { fetchUniversityData, storeFeaturedUniversities, storePopularUniversities, storePreferredUniversities, storeRecommendedUniversities, storeShortlistedUniversities, storeTopUniversities, updateUniversityCourses } from "./universitySlicer";


export function removeStoreDataOfUniversity(dispatch) {
    dispatch(fetchUniversityData([]));
    dispatch(updateUniversityCourses([]));
    dispatch(storePopularUniversities([]));
    dispatch(storeFeaturedUniversities([]));
    dispatch(storeRecommendedUniversities([]));
    dispatch(storeShortlistedUniversities([]));
    dispatch(storePreferredUniversities([]));
    dispatch(storeTopUniversities([]));
}