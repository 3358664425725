import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../CSS file/connleft.css";
import "../CSS file/tabbarstyle.css";
import ConnContent1 from "./Connection content/ConnContent1";
import "../CSS file/conncontent.css";
import { userConnectionWithOthers } from "../../StateManagement/slicers/userSlicer"
import { storeConnectionTypeToRender } from "../../StateManagement/slicers/userSlicer";

export default function ConnectionLeftSide() {
  let dispatch = useDispatch();
  const { tabToRender, userConnectionList } = useSelector((state) => state.user);
  const [toggleState, setToggleState] = useState(1);
  const [connection, setConnnection] = useState([]);
  const [follower, setFollower] = useState("");
  const [following, setFollowing] = useState("");
  const [request, setRequest] = useState("");
  const [connectionList, setConnectionList] = useState([]);
  useEffect(() => {
    dispatch(userConnectionWithOthers());
  }, [userConnectionWithOthers]);
  useEffect(() => {
    if (userConnectionList) {
      setConnectionList(userConnectionList.data);
    }
  }, [userConnectionList]);

  useEffect(() => {
    
    setFollower(connectionList?.followers?.length);
    setFollowing(connectionList?.followings?.length);
    setRequest(connectionList?.pending_requests?.length);
  
  }, [connectionList]);



  const [totalTabs, setTotalTabs] = useState([
    {
      tab: 1,
      tabText: "Followers",
      tabCount: follower ? follower : 0,
    },
    {
      tab: 2,
      tabText: "Following",
      tabCount: following ? following : 0,
    },
    // {
    //   tab: 3,
    //   tabText: "Request",
    //   tabCount: request ? request : 0,
    // },
  ]);
 

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [connectionLists, setConnectionLists] = useState([]);
  const value = 1;



  const renderTabs = totalTabs.map((perTab) => {
    return (
      <button
        className={tabToRender === perTab.tab ? "tabs active-tabs" : "tabs"}
        onClick={() => {
          dispatch(
            storeConnectionTypeToRender({
              type: perTab.tabText,
              tab: perTab.tab,
            })
          );
          toggleTab(perTab.tab);
        }}
        key={Math.random()}
      >
        {perTab.tabText}
        <span
          className={
            tabToRender === perTab.tab
              ? " tab_count_text"
              : "tab_active_count_text"
          }
        >
          {perTab.tab === 1 ? follower : null}
          {perTab.tab === 2 ? following : null}
          {perTab.tab === 3 ? request : null}
        </span>
      </button>
    );
  });

  return (
    <div className="conectin_under_left_side_contianer">
      <div className="">
        <div className="tab_connection_contianer">
          <div className="">
            <div className="bloc-tabs">{renderTabs}</div>
          </div>
        </div>

        <div className="content_tabs_container">
          <div className="content-tabs">
            <div
              className={
                tabToRender === 1 ? "content  active-content" : "content"
              }
            >
              <ConnContent1 />
              {/* <button className="load_more_btn">Load more</button> */}
            </div>

            <div
              className={
                tabToRender === 2 ? "content  active-content" : "content"
              }
            >
              <ConnContent1 />
            </div>

            <div
              className={
                tabToRender === 3 ? "content  active-content" : "content"
              }
            >
              <ConnContent1 />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
