import React from "react";

function ProfileboxSkeleton(props) {
  return (
    <>
      <div
        className="conn_following_box_contianer_skeleton"
        key={Math.random()}
      >
        {/* <div className="conn_img_title_subtitle_container"> */}
        <div className="following_img skeleton skeleton_width skeleton_remove_border" />
        <div className=" skeleton skeleton_text skeleton_width" />
        <div className=" skeleton skeleton_text skeleton_width_short" />
        {/* </div> */}
        <div
          style={{ height: 20, marginTop: 10 }}
          className="skeleton  skeleton_width"
        />
      </div>
      <div
        className="conn_following_box_contianer_skeleton"
        key={Math.random()}
      >
        {/* <div className="conn_img_title_subtitle_container"> */}
        <div className="following_img skeleton skeleton_width skeleton_remove_border" />
        <div className=" skeleton skeleton_text skeleton_width" />
        <div className=" skeleton skeleton_text skeleton_width_short" />
        {/* </div> */}
        <div
          style={{ height: 20, marginTop: 10 }}
          className="skeleton  skeleton_width"
        />
      </div>
      <div
        className="conn_following_box_contianer_skeleton"
        key={Math.random()}
      >
        {/* <div className="conn_img_title_subtitle_container"> */}
        <div className="following_img skeleton skeleton_width skeleton_remove_border" />
        <div className=" skeleton skeleton_text skeleton_width" />
        <div className=" skeleton skeleton_text skeleton_width_short" />
        {/* </div> */}
        <div
          style={{ height: 20, marginTop: 10 }}
          className="skeleton  skeleton_width"
        />
      </div>
      <div
        className="conn_following_box_contianer_skeleton"
        key={Math.random()}
      >
        {/* <div className="conn_img_title_subtitle_container"> */}
        <div className="following_img skeleton skeleton_width skeleton_remove_border" />
        <div className=" skeleton skeleton_text skeleton_width" />
        <div className=" skeleton skeleton_text skeleton_width_short" />
        {/* </div> */}
        <div
          style={{ height: 20, marginTop: 10 }}
          className="skeleton  skeleton_width"
        />
      </div>
      <div
        className="conn_following_box_contianer_skeleton"
        key={Math.random()}
      >
        {/* <div className="conn_img_title_subtitle_container"> */}
        <div className="following_img skeleton skeleton_width skeleton_remove_border" />
        <div className=" skeleton skeleton_text skeleton_width" />
        <div className=" skeleton skeleton_text skeleton_width_short" />
        {/* </div> */}
        <div
          style={{ height: 20, marginTop: 10 }}
          className="skeleton  skeleton_width"
        />
      </div>
    </>
  );
}

export default ProfileboxSkeleton;
